/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2021-01-21 14:39:01
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-03-04 15:33:20
 */
import Vue from 'vue'
import ToDownLoadPopUp from './ToDownLoadPopUp.vue'
let toDownLoadPopUpVue: any

function createToDownLoadPopUp() {
  const vnode = new Vue({
    render: h => h(ToDownLoadPopUp)
  }).$mount()
  document.body.appendChild(vnode.$el)
  return vnode.$children[0]
}

export function openToDownLoadPopUp() {
  if (!toDownLoadPopUpVue) {
    toDownLoadPopUpVue = createToDownLoadPopUp()
  }
  toDownLoadPopUpVue.open()
  return toDownLoadPopUpVue
}
