/*
 * @Descripttion:
 * @Author: xiangdong
 * @Date: 2020-09-09 17:32:47
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-06-07 18:14:01
 */

import { openAppPopUp } from '@/components/OpenAppPopUp'
import { openToDownLoadPopUp } from '@/components/ToDownLoadPopUp'
import { ShareModule } from '_index/modules/share'
import { openScheme, toAppMarket, toAppMarketEnjoy } from '@/common/utils'
import {
  callAppFunc_openApp,
  callAppFunc_webToThirdApp
} from '@/common/utils/bridge'
import { Component, Vue, Watch } from 'vue-property-decorator'

const ua = navigator.userAgent
/**
 * 是否iOS设备
 */
const isIOS = /iphone|ipad|ipod/i.test(ua)

@Component
export class ShareMixin extends Vue {
  getDownloadAppEventInfo(eventId: string) {
    return {
      pageId: this.$route.meta?.pageId,
      eventId,
      eventType: 'isDownloadApp'
    }
  }
  openSchemeing = false
  async handleOpenApp() {
    if (this.openSchemeing) return
    this.openSchemeing = true
    try {
      if (this.$platform === 'chekchek') {
        const { message, data } = await callAppFunc_openApp({
          appPackageName: 'com.kigo.app',
          schemeUrl: 'live.kigo://',
          appDownloadUrl: ShareModule.apkUrl
        })
        this.$toast(
          `${message}${
            data.progress ? '，进度：' + data.progress * 100 + '%' : ''
          }`
        )
      } else if (this.$platform == 'kixmix') {
        if (isIOS) {
          return
        }
        callAppFunc_webToThirdApp({
          appPackageName: 'com.kigo.app'
        })
        console.log('kixmix')
      } else if (
        this.$platform === 'weixin' ||
        (isIOS && this.$platform === 'qq')
      ) {
        openAppPopUp()
      } else {
        const ToDownLoadPopUp = openToDownLoadPopUp()
        try {
          await openScheme('live.kigo://', 3500)
          ToDownLoadPopUp.close()
        } catch (error) {
          //ToDownLoadPopUp.changeState(false)
          toAppMarket()
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.openSchemeing = false
    }
    console.log('打开APP')
  }

  async handleOpenEnjoyApp(downLoadUrl = '', queryUrl = '') {
    if (this.openSchemeing) return
    this.openSchemeing = true
    try {
      if (this.$platform === 'chekchek') {
        const { message, data } = await callAppFunc_openApp({
          appPackageName: 'com.kigo.enjoy',
          //schemeUrl: `live.kigo://kigo.com${queryUrl}`,
          schemeUrl: 'live.kigo://',
          appDownloadUrl: ShareModule.apkUrl
        })
        this.$toast(
          `${message}${
            data.progress ? '，进度：' + data.progress * 100 + '%' : ''
          }`
        )
      } else if (this.$platform == 'kixmix') {
        if (isIOS) {
          return
        }
        callAppFunc_webToThirdApp({
          appPackageName: 'com.kigo.enjoy'
        })
        console.log('kixmix')
      } else if (
        this.$platform === 'weixin' ||
        (isIOS && this.$platform === 'qq')
      ) {
        openAppPopUp()
      } else {
        // const ToDownLoadPopUp = openToDownLoadPopUp()
        try {
          await openScheme(
            `kigo.enjoy://com.kigo.enjoy`,
            3000
            //isIOS ? 11000 : 5500
          )
          //await openScheme('live.kigo://', isIOS ? 11000 : 5500)
          // ToDownLoadPopUp.close()
        } catch (error) {
          console.log('openScheme error', error)
          toAppMarketEnjoy(downLoadUrl)
          // ToDownLoadPopUp.changeState(false)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.openSchemeing = false
    }
    console.log('打开APP')
  }
  handleGoBack() {
    this.$router.back()
  }
  toLiveRoom(liveInfo: {
    uid: any
    stream: any
    pull: any
    live_type: any
    avatar: any
    user_nicename: any
    thumb: any
  }) {
    this.$router.push({
      path: '/share/live-room',
      query: {
        uid: liveInfo.uid,
        url: liveInfo.pull,
        stream: liveInfo.stream,
        isLive: liveInfo.live_type,
        avatar: liveInfo.avatar,
        user_nicename: liveInfo.user_nicename,
        thumb: liveInfo.thumb
      }
    })
  }
}

@Component
export class ShareLayout extends Vue {
  get shareLayoutRefresh() {
    return ShareModule.shareLayoutRefresh
  }

  @Watch('shareLayoutRefresh')
  async onChangeShareLayoutRefresh() {
    if (this.shareLayoutRefresh) {
      if (!this.onShareLayoutRefresh) {
        ShareModule.setShareLayoutRefresh(false)
        return
      }
      try {
        await this.onShareLayoutRefresh()
      } catch (error) {
        console.error(error)
      } finally {
        ShareModule.setShareLayoutRefresh(false)
      }
    }
  }

  onShareLayoutRefresh: null | (() => Promise<any>) = null
}
