





















import { Component, Vue } from 'vue-property-decorator'
import router from '_index/router'
import { toAppMarket } from '@/common/utils'

@Component({
  name: 'ToDownLoadPopUp'
})
export default class ToDownLoadPopUp extends Vue {
  show = false
  state = true
  timer = NaN
  open() {
    this.show = true
    this.changeState(true)
  }

  close() {
    this.show = false
    this.changeState(false)
  }

  changeState(state: boolean) {
    this.state = state
    if (this.state) {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.ellipsis++
        if (this.ellipsis >= 4) {
          this.ellipsis = 1
        }
      }, 500)
    } else {
      clearInterval(this.timer)
      this.timer = NaN
    }
  }

  get ellipsisStr() {
    let str = ''
    for (let index = 0; index < this.ellipsis; index++) {
      str += '.'
    }
    return str
  }

  toDownLoad() {
    this.show = false
    //router.push('/download')
    toAppMarket()
  }

  ellipsis = 3
}
